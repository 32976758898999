
import { Options, Vue } from 'vue-class-component';
import router from '../router';
import LocationService from '@/services/LocationService';
import LocalStorageService from '@/services/LocalStorageService';
import RoleService from '@/services/RoleService';
import UserService from '@/services/UserService';
import TermsAndCondition from '@/components/TermsAndCondition.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
@Options({
  props: ['locationId', 'contactId', 'ach'],
  components: { TermsAndCondition, PrivacyPolicy },
  providers: [LocationService, LocalStorageService, RoleService, UserService],
  emits: ['show-ach-form'],
})
export default class TermsComponent extends Vue {
  userService = new UserService();
  localStorageService: LocalStorageService = new LocalStorageService();
  userId: any = '';
  customerError = false;
  disableFileds = true;
  loading = false;
  messageText = '';
  tandc = false;
  privacyPolicy = false;
  selectedLocationId: any = '';
  locationId: any = null;
  contactId: any = '';
  async mounted() {
    this.loading = true;
    this.selectedLocationId = this.$route.query.locationId || this.locationId;
    this.userId = this.localStorageService.getItem('userId');
    this.loading = false;
  }

  private checkAgreement(e: any, type: string) {
    if (type === 'tandc') {
      this.tandc = e;
    } else {
      this.privacyPolicy = e;
    }
    if (this.tandc && this.privacyPolicy) {
      this.disableFileds = false;
    } else {
      this.disableFileds = true;
    }
  }

  async handleSubmit(ach: any) {
    try {
      this.loading = true;
      await this.userService.acceptTandC(this.userId);
      this.loading = false;
      if (ach) {
        // window.location.reload();
        this.$emit('show-ach-form');
      } else {
        router.push({
          name: 'new order',
          query: {
            locationId: this.locationId,
            customerId: this.contactId,
          },
        });
      }
    } catch (err) {
      this.loading = false;
      alert(
        'There was an error when updating user settings, please try again.',
      );
    }
  }
}
