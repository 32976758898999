
import {Options, Vue} from 'vue-class-component';
import axios from 'axios';
import LocalStorageService from '../services/LocalStorageService';
import RoleService from '../services/RoleService';
import router from '../router';
import {LoginUserRequest} from '@/contracts/LoginUserReqest';
import TermsComponent from '@/components/TermsComponent.vue';

@Options({
  components: {
    TermsComponent,
  },
  beforeCreate() {
    return new Promise((resolve) => {
      if (window.location.search) {
        this.loading = true;
        const url = window.location.search.split('data=');
        const url_value = url[1];
        if (url_value) {
          axios({
            url: `${process.env.VUE_APP_BASE_URL}/authenticate/login?source=zeamster&text=${url_value}`,
            data: {},
            method: 'POST',
          })
            .then(async (resp) => {
              const token = resp.data.authentication.jwt;
              const auth = resp.data.authentication.ot;
              const roles = resp.data.authentication.roles;
              const userId = resp.data.authentication.userId;
              const locationId = resp.data.authentication.location_id;
              const userName = this.user.email
                ? this.user.email?.split('@')[0]
                : resp.data.authentication.username
                ? resp.data.authentication.username?.split('@')[0]
                : '';
              this.localStorageService.store('user-name', userName);
              this.localStorageService.store('user-token', token);
              this.localStorageService.store('auth-token', auth);
              this.localStorageService.store('roles', roles);
              this.localStorageService.store('userId', userId);
              this.localStorageService.store('primary_location_id', locationId);
              this.localStorageService.store(
                'expireIn',
                Number(Date.now()) + 1000 * 60 * 60 * 24 * 1,
              );
              this.selectedLocationId = resp.data.authentication.location_id;
              this.selectedCustomerId = resp.data.authentication.contact_id;

              await this.$store.dispatch('setLocationsOnStorage');
              const location = (
                await this.$store.getters.allLocationList
              ).filter((location) => location.id === locationId)[0];

              if (resp.data.authentication.redirect) {
                this.oauth_url = resp.data.authentication.redirect;
                window.location.href = this.oauth_url;
                console.info('redirected for oauth');
              } else if (
                !resp.data.authentication.tandC &&
                !resp.data.authentication.privacyPolicy
              ) {
                this.ShowTandcandPP = true;
                this.ach_before_check = resp.data.authentication.ACH;
                console.info('redirect for terms and conditions');
              } else if (resp.data.authentication.ACH) {
                console.info('redirected for ACH');
              } else {
                router.push({
                  name: 'new order',
                  query: {
                    customerId: resp.data.authentication.contact_id,
                    locationId: resp.data.authentication.location_id,
                  },
                });
              }
              this.$pendo.initialize({
                visitor: {
                  id: userId,
                  email: this.user.email,
                  full_name: userName,
                  role: roles,
                  location: locationId,
                },
                account: {
                  id: locationId,
                  name: location.name,
                },
              });
              this.loading = false;
              resolve('test');
            })
            .catch((err) => {
              this.loading = false;
              console.error(err);
            });
        }
      } else {
        this.loading = false;
        this.disablebutton = false;
        const userToken = this.localStorageService.getItem('user-token');
        const roles = this.localStorageService.getItem('roles');
        if (this.$route.params.email) {
          const email = this.$route.params.email.toString();
          this.user.email = email;
        }
        if (userToken) {
          if (roles === 'patient') {
            router.push({
              path: `/orders`,
            });
          } else {
            router.push({
              path: `/orders/new`,
            });
          }
        }
        resolve('test');
      }
    });
  },
})
class Login extends Vue {
  localStorageService: LocalStorageService = new LocalStorageService();
  roleService: RoleService = new RoleService(this.localStorageService);
  user: LoginUserRequest = new LoginUserRequest();
  loading = false;
  loginError = false;
  messageText = '';
  disablebutton = false;
  oauthurl = '';
  oauth_url = '';
  oauthflag = false;
  ShowTandcandPP = false;
  selectedLocationId: any = '';
  selectedCustomerId: any = '';
  ach_before_check: any = false;
  async login() {
    this.loading = true;
    this.loginError = false;
    if (this.loginFormValidation()) {
      axios({
        url: `${process.env.VUE_APP_BASE_URL}/authenticate/login?source=zeamster-login`,
        data: this.getAuthData(),
        method: 'POST',
      })
        .then(async (resp) => {
          if (resp.data.authentication.redirect) {
            this.oauthflag = true;
            this.loading = false;
            this.oauthurl = resp.data.authentication.redirect;
          } else {
            const token = resp.data.authentication.jwt;
            const auth = resp.data.authentication.ot;
            const roles = resp.data.authentication.roles;
            const userId = resp.data.authentication.userId;
            const locationId = resp.data.authentication.location_id;
            const userName = this.user.email
              ? this.user.email?.split('@')[0]
              : resp.data.authentication.username
              ? resp.data.authentication.username?.split('@')[0]
              : '';
            this.localStorageService.store('user-name', userName);
            this.localStorageService.store('user-token', token);
            this.localStorageService.store('auth-token', auth);
            this.localStorageService.store('roles', roles);
            this.localStorageService.store('userId', userId);
            this.localStorageService.store('primary_location_id', locationId);
            this.localStorageService.store(
              'expireIn',
              Number(Date.now()) + 1000 * 60 * 60 * 24,
            );
            this.selectedLocationId = resp.data.authentication.location_id;
            this.selectedCustomerId = resp.data.authentication.contact_id;

            await this.$store.dispatch('setLocationsOnStorage');
            const location = (await this.$store.getters.allLocationList).filter(
              (location) => location.id === locationId,
            )[0];

            if (resp.data.authentication.redirect) {
              this.oauth_url = resp.data.authentication.redirect;
              window.location.href = this.oauth_url;
              console.info('redirected for oauth');
            } else if (
              !resp.data.authentication.tandC &&
              !resp.data.authentication.privacyPolicy
            ) {
              this.ShowTandcandPP = true;
              this.ach_before_check = resp.data.authentication.ACH;
              console.info('redirect for terms and conditions');
            } else if (resp.data.authentication.ACH) {
              console.info('redirected for ACH');
            } else {
              await router.push({
                name: 'new order',
                query: {
                  customerId: resp.data.authentication.contact_id,
                  locationId: resp.data.authentication.location_id,
                },
              });
            }
            this.$pendo.initialize({
              visitor: {
                id: userId,
                email: this.user.email,
                full_name: userName,
                role: roles,
                location: locationId,
              },
              account: {
                id: locationId,
                name: location.name,
              },
            });
            if (
              !resp.data.authentication.tandC &&
              !resp.data.authentication.privacyPolicy
            ) {
              await this.acceptTandC(userId);
            }

            this.loading = false;
            this.routeUser('');
          }
        })
        .catch(() => {
          this.loading = false;
          this.loginError = true;
          this.messageText = 'Invalid credentials. Please try again!';
        });
    } else {
      this.loading = false;
      this.loginError = true;
      this.messageText = 'Invalid credentials. Please try again!';
    }
  }

  showACH() {
    this.ShowTandcandPP = false;
  }

  async acceptTandC(userId: string) {
    return axios.put(
      `${process.env.VUE_APP_BASE_URL}/${userId}?acceptTandCandPP=true`,
    );
  }
  getAuthData() {
    return {
      email: this.user.email || '',
      password: this.user.password || '',
    };
  }
  forgotPassword() {
    if (this.user.email) {
      router.push({
        path: `/forgotpassword`,
        query: {
          email: this.user.email,
        },
      });
    } else {
      router.push({
        path: `/forgotpassword`,
      });
    }
  }

  generatePath(destination: string | undefined) {
    this.roleService.getRole();
    let path = '/';
    if (destination) {
      path += destination;
    } else if (this.roleService.isSupport) {
      path = '/items';
    } else if (this.roleService.isPatient) {
      path = '/orders';
    } else {
      path = '/orders/new';
    }

    return path;
  }

  routeUser(destination: string | undefined, customerId?: string | undefined) {
    router.push({
      path: this.generatePath(destination),
      query: {
        customerId,
      },
    });
  }

  loginFormValidation() {
    return (this.user.email && this.user.password);
  }
}

export default Login;
